@import '../../../media-queries.scss';
@import '../_variables.scss';

.ai-generation-ui * {
  pointer-events: auto;
}

.ai-generation-ui {
  // background-color: rgba(255, 0, 0, 0.5);

  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  .topbar {
    pointer-events: none;
    position: fixed;
    top: 0;

    max-width: 414px;
    left: calc(50% - 207px);
    @media (max-width: 413px) {
      left: 0;
    }

    padding: 10px;

    .topbar-items {
      width: 100%;

      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: top;
      justify-content: space-between;

      padding: 10px 15px 10 15px;

      .topbar-item {
        font-size: 10px;
        font-weight: 600;
        line-height: 13px;
        text-decoration: none;
        text-align: center;
        color: white;

        display: flex;
        flex-direction: column;

        padding: 5px 3px;

        .topbar-item-icon {
          background-size: contain;
          background-repeat: no-repeat;
          width: 26px;
          height: 26px;
          margin: auto;
        }

        .topbar-item-label {
          height: fit-content;
          user-select: none;
        }
      }
    }
  }

  .bottombar {
    pointer-events: none;
    position: fixed;
    bottom: 0px;

    width: 100%;
    height: $bottom-navvar-height;
    z-index: 3;

    max-width: 414px;
    left: calc(50% - 207px);
    @media (max-width: 413px) {
      left: 0;
    }

    background-color: black;

    padding: 10px;

    display: flex;
    flex-direction: row;
    gap: 14%;
    align-items: center;
    justify-content: center;

    .bottombar-items {
      width: 100%;
      max-width: 600px;

      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: top;
      justify-content: left;

      padding: 10px;

      .bottombar-separator {
        width: 1px;
        height: 50px;
        background-color: #ffffff;
        opacity: 0.5;
      }

      .bottombar-item {
        font-size: 10px;
        font-weight: 600;
        line-height: 13px;
        text-decoration: none;
        text-align: center;
        color: white;

        display: flex;
        flex-direction: column;

        .bottombar-item-icon {
          background-size: contain;
          background-repeat: no-repeat;
          width: 26px;
          height: 26px;
          margin: auto;
        }
        .bottombar-item-label {
          height: fit-content;
          padding-top: 5px;
          user-select: none;
        }

        &.restart {
          .bottombar-item-icon {
            width: 20px;
            height: 20px;
          }
        }

        &.publish {
          background-color: white;
          color: black;
          border: 2px solid #333;
          border-radius: 10px;
          width: 100%;
          height: 45px;

          margin-top: 3px;

          .bottombar-item-label {
            font-size: 16px;
            font-weight: 600;
            padding-top: 14px;
            user-select: none;
          }
        }

        &.ai-progress {
          width: 72%;

          .progress-title {
            padding-top: 2px;
            font-size: 15px;
            text-align: left;

            .progress-subtitle {
              padding-top: 5px;
              font-size: 11px;
              text-align: left;
              color: #ccc;
              line-height: 16px;
              font-weight: 300;

              @media (max-width: 413px) {
                font-size: 10px;
              }
            }
          }

          .progress-bar {
            width: 72%; // 305px;
            height: 6px;
            border-radius: 10px;
            background-color: #ffffff;
            margin-top: 7px;
          }
        }
      }
    }
  }
}
