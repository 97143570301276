.coll-form-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-height: 400px;
  gap: 10px;
  padding: 0 16px 16px 16px;
  margin-top: 10px;
}

.coll-form-success-congrats {
  font-family: 'Inter', sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 60px;
  color: white;

  text-align: center;

  background-image: linear-gradient(103.68deg, #add7ff 19.74%, #ffc3eb 45.82%, #fbffc8 67.89%);
  background-size: 300%;
  background-position: 0% 0%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: coll-layout-subtitle-anim 10s linear infinite;

  margin-top: 2px;
}

.coll-form-success-message {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: #d4d2ed;
  text-align: center;
  margin-bottom: 60px;
}

.svs-btn.join-discord {
  margin-top: 12px;
  line-height: 35px;
}

.extra-btns-list {
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: block;
    padding: 10px 0;
    counter-increment: coll-form-success-count;

    .extra-button::before {
      content: counter(coll-form-success-count);
      position: absolute;
      left: 25px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 900;
      color: #f2f0ff;
      display: inline-block;
    }
  }
}

.extra-button {
  display: block;
  width: 100%; // 350px; // todo carles
  position: relative;
}

.extra-button {
  text-decoration: none;
  text-align: center;

  &:hover,
  &.completed,
  &.completed:hover {
    text-decoration: underline;
  }

  &.completed {
    cursor: default;
  }

  &:after {
    display: inline-block;
    position: absolute;
    right: 15px;
    width: 20px;
    height: 20px;
    content: url(../../../assets/svg/checkmark.svg);
  }
  &.completed:after,
  &:hover:after {
    content: url(../../../assets/svg/checkmark_green.svg);
  }
}
