@import '../../media-queries.scss';

$bg: transparent; // rgba(0, 0, 0, 0.85);
$title: #fff;
$profileName: white; //  #84829d;
$profileFootnote: #d4d2ed;
$amount: white; //  #6c6a85;
$amountBtnBorder: rgba(255, 255, 255, 0.2); //  #292837;
$amountBtnBorderSelected: rgba(255, 255, 255, 0.2); //  #292837;
$amountBtnText: #999; // #fff;
$amountBtnTextSelected: #fff; // #fff;
$mintBtnBg: #4598fa;
$mintBtnText: #fff;
$footnote: white; //  #84829d;

.Box {
  flex: 1;
  display: flex;
}

.Col {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Row {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.hide {
  display: none !important;
}

.MintBox_Container {
  width: 100%;

  transition: height 0.1s ease-out;

  background-clip: padding-box;
  margin-left: -4px;
  margin-right: -4px;
  z-index: 2;
  font-feature-settings: 'liga' 0;
  box-sizing: border-box;
  background-color: $bg;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: flex-start;
  flex-direction: column;
  display: flex;

  padding-top: 20px;

  .disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  // .button-blue {
  //   text-align: center !important;
  //   margin-bottom: 40px !important;
  // }
}

// ====== Texts =======

.MintBox_Text {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.Profile {
  padding: 0 6px;
}

.Title {
  line-height: 30px;
  font-size: 30px;
  text-align: center;
  color: $title;
}

.Name {
  font-size: 20px;
  text-align: center;
  color: $profileName;
  margin-left: 10px;
  margin-top: 6px;
}

.ProfileFootnote {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $profileFootnote;
  margin-top: 11px;
}

.AmountText {
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  color: $amount;
  // margin-bottom: 11px;
  margin-bottom: 20px;
}

.MaxPerAddress {
  margin-top: 6px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

// ====== Others =======

.PicAndName {
  align-items: center;
  padding: 5px 0;
}

.Picture {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

// .AmountSelector {
//   margin-bottom: 10px;
// }

.AmountBtns {
  justify-content: space-between;
  max-height: 40px;
  padding: 0 40px;
}

.AmountBtn,
.AmountBtn_selected {
  max-width: 50px;
  max-height: 38px;
  border-radius: 21px;
  padding: 8px 16px;
  border: 1px solid $amountBtnBorder;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: $bg;
  color: $amountBtnText;
  cursor: pointer;
  filter: invert(0);

  &:hover {
    border: 1px solid $amountBtnBorderSelected;
    color: $amountBtnTextSelected;
    // filter: invert(0.2);
  }
}

// There's probably a way to do this properly, but I don't know how rn

.AmountBtn_selected {
  color: $amountBtnTextSelected;
  border: 1px solid $amountBtnBorderSelected;
  // filter: invert(1);
  &:hover {
    color: $amountBtnTextSelected;
    border: 1px solid $amountBtnBorderSelected;
    // filter: invert(1);
  }
}

.CustomAmountBtn {
  max-width: 96px;
}

.CustomAmountPicker {
  justify-content: space-between;
  max-height: 40px;
  margin-top: 8px;
  transition: height 0.4s ease-out;
}

.CustomAmountInput {
  max-width: 100px;
  max-height: 38px;
  border-radius: 50px;
  padding: 8px 16px;
  border: 2px solid $amountBtnBorder;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: $bg;
  color: $amountBtnText;

  background-color: rgba(0, 0, 0, 0.5);
  color: white;

  &:hover {
    filter: invert(0.2);
  }
  &:focus-visible {
    filter: invert(0);
    outline: solid;
  }
}

.MintPrice {
  display: block;
  width: 100%;
  margin: 24px 0 4px 0;
  border-top: 1px solid $amountBtnBorder;

  padding-top: 18px;
  p.MintBox_Text {
    text-align: left;
    position: relative;
    margin: 0px 0 11px 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    &.Total {
      font-weight: 800;
      color: $title;
    }
  }

  span {
    position: absolute;
    right: 0;
  }
}

.MintBtn {
  background: $mintBtnBg;
  min-height: 50px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: $mintBtnText;
  cursor: pointer;
}

.Footnote {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* margin-top: 26px; */
  margin-bottom: 67px;
  margin-top: 0;
  color: #ccc;
}

@include respond-below(lg) {
  .AmountBtns {
    padding: 0 15px;
  }
}

@include respond-below(md) {
  .AmountBtns {
    padding: 0 25px;
  }
}

@include respond-below(xs) {
}

@include respond-below(xxs) {
  .MintBox_Container {
    min-width: auto;
  }
}

// Ellipsis animation
.loading::after {
  display: inline-block;
  animation: dotty steps(1, end) 2s infinite;
  content: '';
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

.mintbox-container {
  background-color: rgb(33 37 41 / 70%);
  position: absolute;
  z-index: 999999;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  flex-direction: column;
}

.coll-form-error-msg {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #fd5d5d;
  word-break: break-all;
  text-align: center;
}
